<template>
    <div class="content">
        <div class="web-width">
            <div style="text-align: center;font-size: 20px;margin-bottom: 5%">
                <h1>{{$t('message.password.resetpass')}}</h1>
            </div>
            <el-form ref="passwordForm" :model="passwordForm" :rules="rules" @submit.native.prevent label-width="80px">
                <el-form-item :label="$t('message.personcenter.mailbox')" prop="value">
                    <el-input v-model="passwordForm.value" @keyup.enter.native="next"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button style="width:400px" type="primary" @click="next">{{$t('message.submit')}}</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
  import { deleteCargo } from "../../api/cargo";
  import {forgetPassword} from '../../api/metadata'
  import {validPassword} from '../../api/metadata'

  export default {
    name:'password',
    data() {
      return {
        active: 0,
        passwordForm:{
          value:'',
        },
        rules: {
          value: [
            {
              required: true,
              message: window.vm.$t('message.emailnull'),
              trigger: "blur",

            },
            {  type: "email", message: window.vm.$t('message.emailerror'), trigger: ['blur', 'change'], },
          ],
        },
      };

    },
    methods: {
      next() {
        let formdata=new FormData();
        formdata.append('value',this.passwordForm.value)
        formdata.append('email',this.passwordForm.value)
        this.$refs.passwordForm.validate((valid)=>{
          if(valid){
            validPassword(formdata).then(response=>{
              if(response.error_code==400){
                forgetPassword(formdata).then(response =>{
                  if(response.error_code==200){
                    this.$message.success(window.vm.$t('message.password.getnewpass'))
                    this.$router.push('/login')
                  }
                })
              }else{
                this.$message.warning(window.vm.$t('message.password.mailnoregister'))
              }
            })
          }
        })


      }
    }
  }
</script>

<style lang="scss" scoped>
    *{margin: 0;padding: 0;border: 0;}
    .el-form{width:40%;margin-left: 27%}
    .el-form-item{margin-bottom: 5%}
    .web-width{width:1200px;margin:150px auto;}
</style>